<template>
  <div class="product-order-sticky" v-if="state">
    <div class="product-order-sticky__inner">
      <div class="product-order-sticky__main">
        <div class="product-order-sticky__title">
          {{ product.title }}
        </div>

        <a href="#product-order-config"
           :title="currentOffer.title"
           class="product-order-sticky__config"
           data-scroll-link
           data-offset="-249"
        >
          <PencilIcon class="product-order-sticky__config-icon"/>

          <div class="product-order-sticky__config-title">
            {{ currentOffer.title }}
          </div>
        </a>
      </div>

      <a :href="`/cart-preview-modal`"
         ref="button"
         title="Добавить в корзину"
         class="product-order-sticky__order"
         data-modal
         data-theme="light"
         data-cursor-expand
         data-order-button
         :data-id="`${state.offerId}`"
         data-quantity="1"
         data-buyable="App\Catalog\Entity\Offer"
         :data-product-id="`${id}`"
         :data-category="`${category}`"
         @click="addToCart"
         data-goal-yandex-click="click_kupit"
         :data-goal-vk-click="`click_kupit|${id}`"
         data-goal-google-click="kupit_kartochka#click"
         data-ecommerce-product-page
         :data-ecommerce="dataToEcomm"
      >
        <span class="product-order-sticky__order-price" v-show="price">
          {{ price }}
        </span>
        <span class="product-order-sticky__order-pipe">
          |
        </span>
        <span class="product-order-sticky__order-title">
          В корзину
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import PencilIcon from "../icons/PencilIcon.vue";
import {mapActions, mapState} from "pinia";
import {productOrderStore} from "../../store/catalog.store";
import {cartStore} from "../../store/cart.store";

export default {
  name: "TheProductOrderSticky",
  components: {PencilIcon},
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState(productOrderStore, [
      'product',
      'state',
      'options'
    ]),
    characteristics() {
      return this.options.characteristics;
    },
    offers() {
      return this.options.offers;
    },
    category() {
      return this.options.category;
    },
    currentOffer() {
      return this.offers.find(offer => offer.id === this.state.offerId);
    },
    price() {
      if (!this.offers || !this.offers.find(item => item.id === this.state.offerId)) {
        return null;
      }
      return this.offers.find(item => item.id === this.state.offerId).price;
    },
    dataToEcomm() {
      let charString = '';
      this.state.characteristics.forEach((char) => {
        const charName = this.characteristics.find(item => item.id === char.charId).title;
        const charValues = this.characteristics.find(item => item.id === char.charId).characteristicValues;
        const charValue = charValues.find(charValue => charValue.id === char.charValue).title;

        charString += charName + ': ' + charValue + '\n';
      });

      const price = parseFloat(this.price.replace(/[\s, ₽]/g, ''));

      return JSON.stringify({
        id: this.product.id,
        name: this.product.title + ' ' + this.currentOffer.title,
        description: this.currentOffer.description,
        chars: charString,
        price: price,
        quantity: 1,
      });
    },
  },
  methods:  {
    ...mapActions(cartStore, [
      'add',
    ]),
    addToCart(e) {
      const target = this.$refs.button;
      const buyable = target.dataset.buyable;
      const id = parseInt(target.dataset.id, 10);
      const quantity = parseInt(target.dataset.quantity, 10);
      const productId = parseInt(target.dataset.productId, 10);
      const characteristics = this.state.characteristics;

      document.dispatchEvent(new CustomEvent('cartAdd', {
        detail: {
          buyable: buyable,
          id: id,
          quantity: quantity,
          element: e.target,
          characteristics: characteristics
        }
      }));

      this.add({
        buyable,
        id,
        quantity,
        productId,
        characteristics
      });
    },
  },
}
</script>

<style lang="scss">

</style>